<!--
 * @Descripttion: 定额报价竞价厅
 * @version: 1.0
 * @Author: Niklaus
 * @Date: 2021-08-27 16:08:41
-->
<template>
  <el-main
    v-loading="loading"
    :element-loading-text="loadingText"
    element-loading-spinner="el-icon-loading"
  >
		<el-row type="flex" justify="space-between" align="center">
			<el-col :span="8"><h1 class="mid-title">网上竞价厅</h1></el-col>
			<el-col :span="8" class="text-right">
				<el-button type="danger" @click="$router.push('/onGoing')">退出竞价厅</el-button>
			</el-col>
		</el-row>
		<el-row type="flex" justify="space-between" align="center" class="mt">
			<el-col :span="12">
				<div>
					<span class="base-title">项目名称: {{ project.projectName }}</span>
          <el-button type="primary" v-if="commInfos.length != 0" @click="showCommInfo = true" style="margin-left: 20px">确认承诺书</el-button>
				</div>
        <div class="round " style="margin-top: 10px;">
          竞价总轮次：共{{ project.rounds.length }}轮
        </div>
        <div class="round ml">
          {{ roundsText }}
        </div>
			</el-col>
			<el-col :span="12" class="text-right right-abs">
				<h1 class="serviceTime" v-if="project.processStatus !='20'">当前服务器时间: {{ currentServiceTime }}</h1>
        <div class="right-time">
          <el-tag type="warning" class="mt" v-show="countDownOverTime">{{ countDownOverTime }}</el-tag>
        </div>
			</el-col>
		</el-row>
		<el-row :gutter="20" class="mt">
			<el-col :span="2" class="bw">采购单位</el-col>
			<el-col :span="6" class="desc">{{ project.bnStoreName }}</el-col>
			<el-col :span="2" class="bw">报价方式</el-col>
			<el-col :span="6" class="el-link el-link--primary" style="justify-content: flex-start">定额报价</el-col>
		</el-row>
		<el-row :gutter="20" class="mt-normal">
			<el-col :span="2" class="bw">项目编号</el-col>
			<el-col :span="6" class="desc">{{ project.projectNo }}</el-col>
			<el-col :span="2" class="bw">竞价总轮次</el-col>
			<el-col :span="6" class="desc">共 {{ project.biddRounds}} 轮</el-col>
		</el-row>
    <el-row :gutter="20" class="mt-normal">
			<el-col :span="2" class="bw">竞价开始时间</el-col>
			<el-col :span="6" class="desc">{{ project.biddBeginDatetime }}</el-col>
			<el-col :span="2" class="bw">竞价结束时间</el-col>
			<el-col :span="6" class="desc">{{ project.biddEndDatetime}}</el-col>
		</el-row>

		<!-- 第一轮竞价 -->
		<div  v-for="round in project.rounds" :key="round.id">
      <el-row :gutter="20" class="mid-title mt">
        <el-col :span="11">第{{ round.roundsNo }}轮竞价</el-col>
        <el-col :span="5">预算价格合计（元）：{{ project.sumTotalPriceWithTax }}</el-col>
        <el-col :span="3">税率：{{ Number(round.items[0].taxRate) * 100 + '%' }}</el-col>
        <el-col :span="5" style="text-align: right;">
          <el-button type="success" size="medium" @click="download">下载模板<i class="el-icon-download el-icon--right"></i></el-button>
          <el-upload style="display: inline-block; margin-left: 10px"
            action="sitg"
            :http-request="uploadItem"
            :show-file-list="false"
            :multiple="false"
            accept=".xls,.xlsx,.XLS,XLSX"
            v-if="checkCommInfoEnable && quoteStatus==='1'  &&offerStatus==='1'"
          >
          <el-button type="warning" size="medium">导入报价<i class="el-icon-upload2 el-icon--right"></i></el-button>
        </el-upload>
        </el-col>
      </el-row>
			<el-table :data="items" border stripe class="mt-normal" show-summary :summary-method="getSummaries"  header-row-class-name="tableHeader">
				<el-table-column type="index" label="#"></el-table-column>
          <el-table-column
            :key="tt.key"
            :prop="tt.key"
            :label="tt.text"
            v-for="tt in quotaColumns"
            :align="tt.align"
            :min-width="tt.minWidth"
          />
          <el-table-column label="预算（元）" align="center">
            <el-table-column prop="itemPostTaxPrice" label="除税单价" min-width="10%"></el-table-column>
          </el-table-column>
          <el-table-column label="报价（元）" align="center">
            <el-table-column
              :key="tt.key"
              :prop="tt.key"
              :label="tt.text"
              v-for="tt in colSpan"
              :align="tt.align"
              :width="tt.width"
            />
          </el-table-column>
          <el-table-column label="其他说明" min-width="10%" prop="otherDesc"></el-table-column>

				<el-table-column label="操作" min-width="8%" align="center">
					<template #default="scope">
            <el-button
              size="mini"
              type="primary"
              v-if="checkCommInfoEnable && quoteStatus==='1'  &&offerStatus==='1'"
              @click="onQuote(scope.row, round.id, round.roundsNo)"
            >
              {{ scope.row.price ? '修改报价' : '我要报价' }}
            </el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>
		<!-- 附件上传 -->
		<el-row class="minH">
			<el-upload
        :action="uploadUrl"
        :on-preview="handlePreview"
        :on-remove="handleRemove"
        :before-remove="beforeRemove"
        :before-upload="beforeUpload"
        multiple
        :limit="5"
        :on-exceed="handleExceed"
        :on-success="handleSuccess"
        :on-error="handleError"
        :file-list="fileList"
        class="upload-list-vertical"
			>
				<el-button class="ttt" size="small" type="primary">上传竞价附件</el-button>
				<template #tip>
					<div class="el-upload__tip">单个附件不超过10M，附件数量不超过5个</div>
				</template>
			</el-upload>
		</el-row>
		<el-row class="minH">
			<el-col :span="24" class="upload-list-vertical">
				<el-button type="primary" class="el-button--small" @click="showNetDisk=true">添加网盘链接</el-button>
				<span class="el-upload__tip">附件过大，或者数量过多时，可以提供网盘链接</span>
			</el-col>
		</el-row>
		<el-row>
			<el-col :span="24">
				<ul class="el-upload-list el-upload-list--text">
					<li
					:key="index"
					v-for="(net,index) in netDisk"
					class="el-upload-list__item is-success tempLi">
						<p class="el-upload-list__item-name">
							{{net.ndName}}
						</p>
						<p class="el-upload-list__item-name">
							{{net.ndContent}}
						</p>
						<p class="el-upload-list__item-name">提取码：{{net.ndCode}}</p>
						<label class="el-upload-list__item-status-label">
							<i class="el-icon-upload-success el-icon-circle-check"></i>
						</label>
						<i class="el-icon-close" @click="delNetDisk(index)" ></i>
					</li>
				</ul>
			</el-col>
		</el-row>

    <!-- 我要报价-dialog -->
    <el-dialog title="我要报价" :visible.sync="showQuote" width="850px" top="70px" center :close-on-click-modal="false">
      <el-main>
        <el-form label-width="160px">
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="标的编码：">{{ tempItem.code }}</el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="标的名称：">{{ tempItem.itemName }}</el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="22">
              <el-form-item label="规格型号：">{{ tempItem.standards }}</el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="计量单位：">{{ tempItem.uom }}</el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="采购数量：">{{ tempItem.purchaseQuantity }}</el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="预算除税单价（元）：">
                <el-input :value="tempItem.itemPostTaxPrice" disabled></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="税率（%）：">
                <el-input :value="Number(project.rounds[0].items[0].taxRate) * 100" disabled></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="除税单价（元）：">
                <el-input
                  v-model="itemQuote"
                  @input="onInput"
                  type="number"
                />
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="含税单价（元）：">
                <el-input v-model="tempItem.preTaxPrice" disabled></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="金额（元）：">
                <el-input v-model="tempItem.amount" disabled></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="价税合计（元）：">
                <el-input v-model="tempItem.price" disabled></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>

        <el-row class="tips-price">
          <p>温馨提示：竞价排名规则</p>
          <p>价税合计总额越低，排名越靠前</p>
          <p>报价相同的，报价早的排名靠前</p>
        </el-row>
      </el-main>

      <span slot="footer">
        <el-button @click="showQuote = false" type="info">取消</el-button>
        <el-button type="success" @click="handleQuote">确定</el-button>
      </span>
    </el-dialog>

		<!-- 添加网盘链接 -- Dialog -->
		<el-dialog :visible.sync="showNetDisk" :close-on-click-modal="false" width="600px" center>
			<template #title>
				<h1 class="base-title">添加网盘链接</h1>
			</template>
			<el-form :model="netDiskForm" ref="netDiskForm" label-position="left" label-width="80px">
				<el-form-item label="名称" prop="ndName" :rules="[{required:true, message:'请输入名称',trigger:'blur'}]">
					<el-input v-model="netDiskForm.ndName"></el-input>
				</el-form-item>
				<el-form-item label="网址" prop="ndContent" :rules="[{required:true, message:'请输入有效的链接地址',trigger:'blur'}]">
					<el-input
					type="textarea"
					v-model="netDiskForm.ndContent"
					:autosize="{minRows:3, maxRows:6}"
					>
					</el-input>
				</el-form-item>
				<el-form-item label="提取码" prop="ndCode">
					<el-input v-model="netDiskForm.ndCode"></el-input>
				</el-form-item>
				<el-form-item>
					<el-button type="info" size="medium" @click="showNetDisk=false">取消</el-button>
					<el-button type="primary" @click="addNetDisk('netDiskForm')" size="medium">保存</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>

    <!-- 竞价承诺书-Dialog -->
		<el-dialog :visible.sync="showCommInfo" :close-on-click-modal="false" width="650px" center>
			<template #title>
				<p class="mid-title">竞价承诺</p>
			</template>
			<el-main>
				<p class="desc">本项目需要供应商履行以下承诺</p>
				<!-- 选中后， 会把 label 绑定的数据 存放到  selectedCommInfos 中 -->
				<el-checkbox-group v-model="selectedCommInfos" :disabled="checkCommInfoEnable">
					<el-checkbox v-for="item in commInfos" :key="item.id" :label="item.id" class="checkbox-comminfo">
							<a style="color:#0090FF" @click="handleView(item.commUrl)">{{item.commName}}</a>
					</el-checkbox>
				</el-checkbox-group>
			</el-main>

			<span slot="footer">
				<el-button v-if="!checkCommInfoEnable" @click="showCommInfo = false">取消</el-button>
				<el-button v-if="!checkCommInfoEnable" type="primary" @click="onConfirmComm">确认</el-button>
				<el-button v-if="checkCommInfoEnable" @click="showCommInfo = false">关闭</el-button>
			</span>
		</el-dialog>

    <!-- 反向竞拍项目 供应商报价排名 -->
		<el-dialog :visible.sync="showRanking" :show-close="false" :close-on-click-modal="false" width="800px" center>
			<template #title>
				<p class="mid-title">供应商竞价排名</p>
			</template>
			<el-main style="padding: 0">
				<el-table
          :data="rankingData"
          style="width: 100%"
          class="ranking-table"
          header-row-class-name="tableHeader"
        >
          <el-table-column
            v-for="column in rankingColumns"
            :key="column.key"
            :prop="column.key"
            :label="column.text"
            :min-width="column.minWidth"
            class-name="ranking-label"
          >
          </el-table-column>
        </el-table>
			</el-main>

			<span slot="footer">
				<el-button type="primary" @click="handleConfirmRanking">确认</el-button>
			</span>
		</el-dialog>
	</el-main>
</template>

<script>
import { dateFormat, countDown } from "@/utils/date";
import { multiplication, toNumFix, add} from '@/utils/math';
import global from "@/common/global";
import { httpPost, httpGet, httpPut ,httpDelete} from "@/utils/http";
export default {
	name: "BidHall",
	data() {
		return {
			serviceTime: "",
			countDownOverTime: "",
      serviceTimeInterval:null,
      quoteStatusInterval: null,
      quoteStatus:'0',
			project: {},
			itemQuote: "",
			tempItem: {},
			currentRound:{},
      items:[],
      quotaColumns:[
        { key: "secondTypeName", text: "分类", align: "center", minWidth: "10%" },
        { key: "itemName", text: "名称", align: "center", minWidth: "15%" },
        { key: "code", text: "编码", align: "center", minWidth: "10%" },
        { key: "standards", text: "规格型号", align: "center", minWidth: "10`%" },
				{ key: "detailParam", text: "详细参数", align: "center", minWidth: "10%" },
        { key: "uom", text: "计量单位", align: "center", minWidth: "6%" },
        { key: "purchaseQuantity", text: "采购数量", align: "center", minWidth: "6%" },
      ],
      colSpan: [
        { key: "postTaxPrice", text: "除税单价", align: "center", width: "100px" },
        { key: "preTaxPrice", text: "含税单价", align: "center", width: "100px" },
        { key: "amount", text: "金额", align: "center", width: "100px" },
        { key: "price", text: "价税合计", align: "center", width: "100px" },
      ],
			showQuote: false,
			urls: {
        serviceTime: "/ewbid/bidding/tbEwProjectSupplierOfferRecords/getServiceTime",
				quote: "/ewbid/bidding/tbEwProjectSupplierOfferRecords/savePrice",
				commInfo: "/ewbid/bidd/tbEwProjectCommInfo/selectByProjectInfo",
				saveCommInfo:"/ewbid/bidd/tbEwSupplierConfiirmComm/saveSupplierConfiirmComm",
				checkedCommInfos:"/ewbid/bidd/tbEwProjectCommInfo/selectCheckedCommInfo",
        getItem: '/ewbid/bidding/tbEwProjectSupplierOffer/getItemAndQuote',
				deleteFile:'/ewbid/bidbase/tbsAttachment/customDelete',//删除附件
        /** 获取竞价供应商的竞价排名  */
        rankingUrl: '/ewbid/bidding/tbEwProjectSupplierOfferSum/toConfirmlist',
        /** 供应商确认竞价排名 */
        confirmUrl: '/ewbid/bidding/tbEwProjectSupplierOfferSum/doConfirm'
      },
			fileList:[],
			// 网盘链接
			showNetDisk:false,
			netDisk:[],
			netDiskForm: {
				name:'',
				ndName: '',
				ndCode: '',
			},
			uploadUrl:global.gateway+"/ewbid/bidbase/tbsAttachment/upload",
      importUrl:'/ewbid/bidding/tbEwProjectSupplierOffer/importQuote',
      roundsText: '',
      showCommInfo: false,
      selectedCommInfos:[],
			//项目结束状态
			projectEndStatus:"",
			commInfos:[],
			checkCommInfoEnable: false,
			overSize: false,
			currentRoundsNo: 0,
			offerStatus: '1',
      loading: true,
      loadingText: '数据加载中..',
      showRanking: false,
      /** 供应商竞价排名 数据 */
      rankingColumns: [
        { key: "seqNum", text: "排序", minWidth: "10%" },
        { key: "supplierStoreName", text: "供应商名称", minWidth: "35%" },
        { key: "price", text: "报价（元）", minWidth: "20%" },
        { key: "offerDatetime", text: "最终报价时间", minWidth: "25%" },
      ],
      rankingData: [],
      count1: 0,
      count2: 0,
      overCount: false,
      overCount1: false,
      currentServiceTime: '',
      taxRate: ''
		};
	},
	created() {
		let _this = this;
		this.project = this.$ls.get("currentProject");
    this.taxRate = this.project.rounds[0].items[0].taxRate
		if(this.project.projectSupplierMap.offerStatus){
			this.offerStatus = this.project.projectSupplierMap.offerStatus;
		}else{
			this.offerStatus = '1';
		}
    this.getServiceTime()

    this.getProjectItem()
		//处理上传的url将邀请竞价供应商关联表的id作为url参数
		if(this.project.projectSupplierMap){
			this.uploadUrl = this.uploadUrl+"?projectId="+this.project.projectSupplierMap.id
			//查询附件
			let url = global.gateway+"/ewbid/bidbase/tbsAttachment/getAttachmentListWithBusinesskey"
			httpGet(url,{
				businesskey:this.project.projectSupplierMap.id
			}).then(res=>{
				if(res.status=="200"&&res.data.retCode=="0"){
					//列表
					if(res.data.attachments){
						for(let ii=0;ii<res.data.attachments.length;ii++){
							let ff = {}
							ff.id = res.data.attachments[ii].id
							ff.name = res.data.attachments[ii].attachmenttitle + "." + res.data.attachments[ii].extend
							ff.url = res.data.attachments[ii].url
							//
							_this.fileList.push(ff)
						}
					}
				}
			}).catch(err=>{
				alert(JSON.stringify(err))
			})
			//查询网盘附件
			url = global.gateway+"/ewbid/bidding/tbEwProjectSupplierOfferRecords/getCommsWithBusinesskey"
			httpGet(url,{
				businesskey:this.project.projectSupplierMap.id
			}).then(res=>{
				if(res.status=="200"&&res.data.retCode=="0"){
					if(res.data.comms){
						for(let ii=0;ii<res.data.comms.length;ii++){
							let co = {}
							co.id = res.data.comms[ii].id
							co.ndName = res.data.comms[ii].ndName
							co.ndContent = res.data.comms[ii].ndContent
							co.ndCode = res.data.comms[ii].ndCode
							//
							this.netDisk.push(co)
						}
					}
				}
			}).catch(err=>{
			alert(JSON.stringify(err))
			})
		}

		//查询当前项目承诺书
		this.selectCommInfos()
	},
	//释放
	destroyed() {
    this.overCount = true
    this.overCount1 = true
	},

	methods: {

    /**
     * @Description: 查询项目条目数据
     * @Author: Niklaus
     * @Date: 2021-09-16 19:51:28
     */
    getProjectItem(){
      let supplierStoreId = this.project.supplierStoreId;
      httpGet(global.gateway + this.urls.getItem,{projectId: this.project.id, supplierStoreId}).then( ({data}) => {
        if(data.success) {
          this.loading =  false
          this.items = data.result
        }
        else
          this.$message.error(data.message)
      })
    },

    /**
     * @Description: 获取服务器时间，并定时更新时间
     * @Author: Niklaus
     * @Date: 2021-09-16 16:46:50
     */
    getServiceTime(){
      httpGet(global.gateway + this.urls.serviceTime).then( res => {
        this.count1 = 0
        this.count2 = 0
        this.overCount1 = false
        this.overCount = false
        this.serviceTime = res.data.result
        this.projectQuoteState()
        this.updateServiceTime()
      })
    },

    updateServiceTime() {
      this.count1++;
      let t1 =  new Date().getTime()
      let serviceTime = new Date(this.serviceTime)
      serviceTime.setSeconds(serviceTime.getSeconds() + this.count1)
      this.currentServiceTime = dateFormat(serviceTime)
      let t2 = new Date().getTime()
      let nextTime = 1000 - (t2 - t1)
      if(!this.overCount1) {
        setTimeout(() => {
          this.serviceTimeInterval = this.updateServiceTime()
        }, nextTime);
      }

    },

    /**
     * @Description: 根据轮次信息，更新显示的文字及 倒计时
     * @Author: Niklaus
     * @Date: 2021-09-16 16:48:28
     */
    projectQuoteState(){
      setTimeout(() => {
        this.quoteStatusInterval = this.newCountDown()
      }, 1000);
    },
		/**
		* @Description: 监听报价数字,(四舍五入)计算其他数字
		* @Author: Niklaus
		* @Date: 2021-09-05 21:12:23
		*/
		onInput(){
			let temp = this.itemQuote
      if(!temp) return
      if(this.itemQuote < 0) {
        this.itemQuote = 0
        this.$message.error('输入值最小为 0')
      }else if(parseFloat(this.itemQuote) > parseFloat(this.tempItem.itemPostTaxPrice)) {
        this.$message.error('不可超过预算单价')
      }else{
        let tt = this.itemQuote.split(".");
        if(tt.length>1 && tt[1].length>2) {
          this.$message.error('最多输入两位小数')
          this.itemQuote = tt[0] +"." + tt[1].substring(0,2)
        }
      }
      this.tempItem.preTaxPrice = toNumFix(multiplication(this.itemQuote, Number(this.taxRate)+1), 2)
      this.tempItem.amount = toNumFix(multiplication(this.itemQuote, this.tempItem.purchaseQuantity), 2)
      this.tempItem.price = toNumFix(multiplication(this.tempItem.preTaxPrice,this.tempItem.purchaseQuantity), 2)
    },
		/**
		* @Description: 提交报价，并将报价数据写入缓存
		* @Author: Niklaus
		* @Date: 2021-09-03 15:24:41
		*/
		handleQuote() {
      if(this.project.processStatus == '20') {
        this.$message.error("项目已结束，不允许报价！")
				return;
      }
			if (!this.itemQuote|| parseFloat(this.itemQuote) < 0) {
				this.$message.error("请输入大于0的金额")
				return;
			}else if(parseFloat(this.itemQuote) > parseFloat(this.tempItem.itemPostTaxPrice)) {
        this.$message.error('不可超过预算单价')
        return
      }

      this.loading = true
      this.loadingText = '提交报价中...'
			let data = {
				projectId: this.project.id,
				supplierStoreId: this.project.supplierStoreId,
				itemId: this.tempItem.itemId,
				roundsId: this.currentRound.id,
				roundsNo: this.currentRound.roundsNo,
				postTaxPrice: this.itemQuote,
			};
      data.createBy = this.$ls.get("userInfo").id
			httpPost(global.gateway + this.urls.quote, data).then((res) => {
				if (res.data.success) {
          this.loading = false
					this.$message({ type: "success", message: "报价成功！" });
          this.showQuote = false;
					this.getProjectItem()
					this.$ls.set("currentProject", this.project);
				} else {
          this.loading = false
					this.$message({ type: "error", message: res.data.message,duration:5000 });
					if(res.data.message.indexOf('当前轮次不允许报价')>1){
						this.offerStatus = '0';
					}
				}
			}).catch((error) => {
				this.$message.error("报价失败");
			});
			//

		},
		/**
		* @Description: 报价前的数据处理
		* @Author: Niklaus
		* @Date: 2021-09-03 15:47:23
		* @param {*} row      当前条目
		* @param {*} roundId  当前轮次ID
		* @param {*} roundsNo 当前轮次编号
		*/
		onQuote(row,roundId, roundsNo) {
			this.currentRound.id = roundId
			this.currentRound.roundsNo = roundsNo
			this.showQuote = true;
			this.tempItem = row;
      this.itemQuote = row.postTaxPrice || '';
		},
		//
		handleRemove(file, fileList) {
      if(file.id){
        let url = global.gateway+this.urls.deleteFile;
        let params={
          id:file.id,
          businesskey: this.project.projectSupplierMap.id
        }
        httpDelete(url,params).then(res=>{
          if(res.data.retCode=='0'){
            //删除成功
          }else{
            this.$message.error('删除失败！');
          }
        })
      }
		},
		handlePreview(file) {
			console.log(file);
		},
		handleExceed(files, fileList) {
			this.$message.warning(`当前限制选择 5 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
		},
		beforeRemove(file, fileList) {
     let confirmRemove = false;
     //如果超过文件限制大小，不提示，直接删除
     if(this.overSize){
        confirmRemove = true;
      }else{
        confirmRemove = this.$confirm(`确定移除 ${ file.name }？`)
      }
      this.overSize = false;
      return confirmRemove
		},
		//上传前确定文件大小10M
		beforeUpload(file){
      const isLt1M = (file.size/1024/1024 < 10);
      if(!isLt1M){
        this.overSize = true;
        this.$message.error('上传文件大小不能超过 10MB!');
        return false;
      }
      this.overSize = false;
      return isLt1M
		},
		//上传成功后
		handleSuccess(res,file,fileList){
			let ret = res
			if(ret.retCode=="0"){
				let attachmentId = ret.attachmentId
				//复写id
				file.id = attachmentId
				//
				this.$message.info("上传成功")
			}else{
				this.$message.error("上传失败！")
				return
			}
		},
		handleError(err,file,fileList){
			alert(JSON.stringify(err))
		},
		/**
		* @Description: 添加网盘链接
		* @Author: Niklaus
		* @Date: 2021-08-25 14:52:49
		*/
		addNetDisk(formName){
			this.$refs[formName].validate( valid =>{
				if(valid) {
					this.netDisk.push({...this.netDiskForm})
					this.showNetDisk=false
					this.netDiskForm =  {
						ndName: '',
						ndContent:'',
						ndCode: '',
					}
					//
					let ii = this.netDisk.length - 1;
					let url = global.gateway+"/ewbid/bidding/tbEwProjectSupplierOfferRecords/insertComm";
					httpPost(url,{
						businesskey:this.project.projectSupplierMap.id,
						ndName:this.netDisk[ii].ndName,
						ndContent:this.netDisk[ii].ndContent,
						ndCode:this.netDisk[ii].ndCode
					}).then(res=>{
						if(res.status=="200"&&res.data.retCode=="0"){
							if(res.data.id){
								this.netDisk[ii].id = res.data.id
							}
							//提交
							this.$message({
								message: '已成功添加',
								type: 'success'
							})
						}
					}).catch(err=>{
						alert(JSON.stringify(err))
					})
				}else {
					this.$message('校验未通过');
				}
			})
		},
		/**
		* @Description: 页面删除网盘链接
		* @Author: Niklaus
		* @Date: 2021-08-25 15:58:08
		* @param {*} index
		*/
		delNetDisk(index) {
			//获取id
			if(this.netDisk[index].id){
				let id = this.netDisk[index].id
				let url = global.gateway+"/ewbid/bidding/tbEwProjectSupplierOfferRecords/deleteComm"
				httpGet(url,{
					id:id
				}).then(res=>{
					if(res.status=="200"){
						console.log(JSON.stringify(res.data))
					}
				}).catch(err=>{
					alert(JSON.stringify(err))
				})
			}
			this.netDisk.splice(index,1)
		},
		//查询承诺书
		selectCommInfos(){
			let params={}
			params.projectId = this.project.id;
			httpGet(global.gateway+this.urls.commInfo,params).then(res=>{
				if(res.data.success){
					this.commInfos = res.data.result || [];
          if(this.commInfos.length == 0)
            this.checkCommInfoEnable = true
          else {
            this.selectCheckedCommInfos()
          }
				}else{
					this.$message.error('查询项目承诺书失败!');
				}
			}).catch(err=>{this.$message.error('查询项目承诺书失败!');});
		},
		//查询已选择的承诺书
		selectCheckedCommInfos(){
			let params={}
			params.projectId = this.project.id;
      params.supplierStoreId = this.$ls.get("storeInfo").id
			httpGet(global.gateway+this.urls.checkedCommInfos,params).then(res=>{
				if(res.data.success){
					let checkedCommInfos = res.data.result;
					this.commInfos.forEach(item1=>{
						checkedCommInfos.forEach(item2=>{
							if(item1.id==item2.id){
								this.selectedCommInfos.push(item1.id);
							}
						});
					});
					if(checkedCommInfos.length==this.commInfos.length){
						this.checkCommInfoEnable = true;
						this.showCommInfo = false;
					}else{
						this.checkCommInfoEnable = false;
						this.showCommInfo = true;
					}

				}else{
					this.$message.error('查询项目承诺书失败!');
				}
			}).catch(err=>{this.$message.error('查询项目承诺书失败!');});
		},
    //查看链接
    handleView(url){
      if(url){
        window.open(url);
      }
    },
		//选定 承诺书
		onConfirmComm(){
			this.showCommInfo = false;
			if(this.selectedCommInfos.length==this.commInfos.length){
				this.checkCommInfoEnable = true
			}else{
				this.checkCommInfoEnable = false
			}
			let dataObj = {};
			dataObj.projectId = this.project.id;
			dataObj.projectNo = this.project.projectNo;
      dataObj.supplierStoreId = this.$ls.get("storeInfo").id
      dataObj.supplierStoreName =  this.$ls.get("storeInfo").storeName
      dataObj.createBy = this.$ls.get("userInfo").id
			dataObj.checkAllCommInfo = this.checkCommInfoEnable;
			let list = [];
			this.selectedCommInfos.forEach(item1=>{
				this.commInfos.forEach(item2=>{
					if(item1==item2.id ){
						list.push(item2);
					}
				});
			});
			dataObj.list = list;
			httpPost(global.gateway+this.urls.saveCommInfo,dataObj).then(res=>{
				if(res.data.success){
					this.$message.success('保存成功!');
				}else{
					this.$message.error('保存失败!');
				}
			}).catch(err=>{this.$message.error('保存失败!')});
		},

    newCountDown() {
      /** 项目终止 */
      if(this.project.processStatus == '20') {
        this.overCount = true
        this.overCount1 =  true
        clearTimeout(this.serviceTimeInterval)
        clearTimeout(this.quoteStatusInterval)
        this.quoteStatus = '2'
        this.roundsText = '项目已结束'
        this.items.forEach(item => {
          if(item.processDetailStatus == '22') {
            this.roundsText = '项目已终止'
          }
        })
        return
      }
      if(this.count2 == 60) {
        this.overCount1 = true
        this.overCount = true
        clearTimeout(this.serviceTimeInterval)
        clearTimeout(this.quoteStatusInterval)
        this.getServiceTime()
        return
      }
      let t1 = new Date().getTime()
      ++this.count2
      let endTime = new Date(this.project.biddEndDatetime).getTime()
      let currentServiceTime = new Date(this.serviceTime).getTime() + this.count2 * 1000
      if(new Date(this.project.biddBeginDatetime).getTime() > currentServiceTime) {
        this.quoteStatus = '0'
        this.roundsText = '报价未开始'
        this.countDownOverTime ="距报价开始时间：" + countDown(new Date(currentServiceTime), new Date(this.project.biddBeginDatetime))
      }else if(endTime < currentServiceTime) {
        this.quoteStatus = '2'
        this.roundsText = '报价已结束'
        this.countDownOverTime = ""
        this.overCount = true
        if(this.project.quotationMethod == '4')
          this.getRankingData(this.project.id, this.project.supplierStoreId)
      }
      this.currentRoundsNo = 0;
      for (let i = 0; i < this.project.rounds.length; i++) {
        let round = this.project.rounds[i];
        if(currentServiceTime >= new Date(round.biddBeginDatetime).getTime() && currentServiceTime <= endTime) {
          this.roundsText = `当前轮次：第${i+1}轮进行中`
          if(this.project.quotationMethod == '4')
            this.countDownOverTime = `距竞价结束时间：` + countDown(new Date(currentServiceTime), new Date(round.biddEndDatetime))
          else
            this.countDownOverTime = `距第${i+1}轮结束：` + countDown(new Date(currentServiceTime), new Date(round.biddEndDatetime))
          this.quoteStatus = '1'
          this.currentRoundsNo = (i+1);
          break
        } else if(currentServiceTime < new Date(round.biddBeginDatetime).getTime()) {
          this.roundsText = `当前轮次：第${i+1}轮未开始`
          if(this.project.quotationMethod == '4')
            this.countDownOverTime = `距报价开始：` + countDown(new Date(currentServiceTime), new Date(round.biddBeginDatetime))
          else
            this.countDownOverTime = `距第${i+1}轮开始：` + countDown(new Date(currentServiceTime), new Date(round.biddBeginDatetime))

          this.quoteStatus = '0'
          break
        }
      }
      if(!this.overCount) {
        let t2 = new Date().getTime()
        let nextTime = 1000 - (t2 - t1)
        if(nextTime < 0) nextTime = 0
        setTimeout(() => {
          this.newCountDown()
        }, nextTime);
      }
    },


    /**
     * @Description: 获取供应商的竞价排名
     * @Author: Niklaus
     * @Date: 2022-05-22 12:18:06
     * @param projectId  项目ID
     * @param supplierStoreId 供应商Id
     */
    getRankingData(projectId, supplierStoreId){
      httpGet(global.gateway + this.urls.rankingUrl,{projectId,supplierStoreId}).then(res => {
        if(res.data.success) {
          this.rankingData = res.data.result
          if(this.rankingData.length > 0) {
            this.rankingData.forEach(item => {
              if(!item.price)
                item.price = '未报价'
            })
            this.showRanking = true
          }
        }
      }).catch(error => {
        console.log(error)
      })
    },

    /**
     * @Description: 供应商确认竞价排名
     * @Author: Niklaus
     * @Return:
     * @Date: 2022-05-22 13:31:50
     */
    handleConfirmRanking(){
      let data = {projectId: this.project.id, supplierStoreId: this.project.supplierStoreId}
      httpPut(global.gateway+ this.urls.confirmUrl, data).then(res => {
        if(res.data.success) {
          this.showRanking = false
          this.$message.success('确认完成')
        }
      })
    },

    /**
     * @Description: 下载模版
     * @Author: Niklaus
     * @Return:
     * @Date: 2022-07-06 10:50:20
     */
    download(){
      window.location.href=global.gateway + "/ewbid/bidding/tbEwProjectSupplierOffer/quoteTemplate?projectId=" + this.project.id
    },

    /**
     * @Description: 上传报价
     * @Author: Niklaus
     * @Return:
     * @Date: 2022-07-06 10:50:25
     * @param {*} params
     */
    uploadItem(params){
      let data = new FormData();
      data.append("file", params.file)
      data.append("projectId", this.project.id)
      data.append("roundId", this.project.rounds[0].id) /** 定额报价项目只有一个轮次 */
      data.append("supplierStoreId", this.project.supplierStoreId)
      data.append("userId",this.$ls.get("userInfo").id)
      let tips = this.$message({
        type:'success',
        message: '数据上传中，请稍候....',
        duration: 0
      })
      httpPost(global.gateway+this.importUrl, data).then(res =>{
        if(res.data.success){
          tips.close()
          this.getProjectItem()
          this.$message.success('上传成功')
        }
        else
          this.$message.error(res.data.message)
      }).finally(() =>{
        tips.close()
      })
    },

    getSummaries(param){
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 10) {
          sums[index] = '合计：';
          return;
        }else if(index < 10 || index >12) {
          sums[index] = ''
          return
        }

        const values = data.map(item => Number(item[column.property]));
        if (!values.every(value => isNaN(value))) {
          sums[index] = values.reduce((prev,curr) => add(prev, curr))
        } else {
          sums[index] = '合计错误';
        }
      });
        return sums;
    },


	}
}
</script>

<style lang="scss" scoped>
.serviceTime {
  color: #ed5565;
  font-size: 22px;
  line-height: 30px;
}
.serviceTime + .mt {
  font-size: 24px;
  padding: 8px 20px;
  border-radius: 10px;
  height: auto;
}
.quote {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
}
.quote .el-col {
  padding: 7.5px 0;
}
.minH {
  margin-top: 30px;
  padding-bottom: 20px;
}

.tips-price {
  text-align: center;
  padding: 20px;
  background-color: #FAFAFA;
  margin-top: 15px;
  p {
    line-height: 20px;
    &:first-child {
      color: #FF3B50;
    }
  }
}
.round {
  display: inline-block;
  background-color: #EBFEFB;
  border: 1px solid #00A893;
  color: #00CCB2;
  padding: 10px 18px;
  border-radius: 5px;
  font-weight: bold;
  font-size: 22px;
}
.checkbox-comminfo {
  display: block; padding: 10px 0;
}
.tempT {
  display: inline-block;
  font-weight: bold;
  margin-right: 15px;
  margin-top: 15px;
}
.desc {
  padding-left: 15px;
}
.right-abs {
  position: absolute;
  right: 0;
}
.right-time .el-tag {
    font-size: 24px;
    padding: 8px 20px;
    border-radius: 10px;
    height: auto;
}
.el-table--enable-row-transition .el-table__body td.ranking-label {
  border-bottom: none;
}


</style>